document.addEventListener("DOMContentLoaded", (event) => {
    const {
        getDefaultContext,
        trackClicks,
        trackStartAnalysisEvent,
        trackJobEvents,
        trackJobsSearched,
        trackPrelimFormViewed,
        trackSiteSearch,
        initContactFormTrackers,
        trackEnquiryListeners,
    } = SegmentEvents;

    const context = getDefaultContext();

    trackClicks();
    trackStartAnalysisEvent();
    initContactFormTrackers(context);
    trackJobEvents(context);
    trackJobsSearched(context);
    trackPrelimFormViewed(context);
    trackSiteSearch(context);

    if (!window.location.pathname.includes("/jobs/")) {
        trackEnquiryListeners("Lead Submitted", {}, context);
    }
});

const SegmentEvents = (function () {
    const pageTitleCleaned = document.title.split("|")[0].trim();

    function getIdempotencyToken() {
        return idempotencyToken;
    }

    function getDefaultContext() {
        const [brand, region] =
            typeof domainFound !== "undefined" ? domainFound[1] : [null, null];

        return {
            brand,
            region,
            idempotencyToken,
        };
    }

    function trackEvent(eventName, traits) {
        if (typeof analytics === "undefined") {
            console.error("Segment analytics was not initialized.");
            return false;
        }

        analytics.track(eventName, traits);
    }

    function trackClicks() {
        const htmlElements = document.querySelectorAll("[data-ik-click]");

        htmlElements.forEach((element) => {
            element.addEventListener("click", () => {
                const context = getDefaultContext();
                const data = element.dataset;
                const properties = {};

                for (const property in data) {
                    if (property.startsWith("prop")) {
                        const formattedProperty = property
                            .replace("prop", "")
                            .toLowerCase();
                        properties[formattedProperty] = data[property];
                    }
                }

                analytics.track(data.ikClick, properties, { context });
            });
        });
    }

    function trackStartAnalysisEvent() {
        const context = getDefaultContext();
        const startAnalysisBtn = document.querySelector("#start-analysis");

        startAnalysisBtn &&
            startAnalysisBtn.addEventListener("click", () => {
                analytics.track("Start Analysis", {}, { context });
            });
    }

    function addUrlTagsToContext(context) {
        URL_TAGS.forEach((tag) => {
            const tagValue = cookieHandler().getCookie(tag);

            if (tagValue !== "") {
                if (!("campaign" in context)) {
                    context["campaign"] = {};
                }

                tag = tag.replace("utm_", "");

                context["campaign"][tag] = tagValue;
            }
        });

        return context;
    }

    function getFirstLastName(traits, fullName) {
        if (typeof fullName === "string" && fullName != "") {
            const first_name = fullName.split(" ").slice(0, -1).join(" ");
            const last_name = fullName.split(" ").slice(-1).join(" ");

            return { ...traits, first_name, last_name };
        }

        return traits;
    }

    function getJobIdLanguage(jobLink) {
        const jobTerms = jobLink.split("-");
        return {
            job_id: jobTerms.pop(),
            language: jobTerms.pop(),
        };
    }

    function getAttrProps(element, fieldsArray) {
        let props = {};
        fieldsArray.forEach((field) => {
            const el = element.querySelector(
                `[segment-prop-${field.replace("_", "-")}]`
            );
            const value = el ? el.innerText.trim() : null;

            value && (props[field] = value);
        });

        return props;
    }

    function getPrelimFormIds() {
        const form_id = getParamFromUrl("jid");
        const session_id = getParamFromUrl("session");

        return {
            session_id,
            form_id,
        };
    }

    function trackPrelimFormViewed(context) {
        const jotformIframe = document.querySelector(".jotform-iframe");
        let hasFired = false;

        jotformIframe &&
            jotformIframe.addEventListener("load", () => {
                if (!hasFired) {
                    const props = { ...getPrelimFormIds() };

                    if (
                        !Object.entries(props).some(
                            ([_, value]) => value === ""
                        )
                    ) {
                        analytics.track("Preliminary Form Viewed", props, {
                            context,
                        });
                    }

                    hasFired = true;
                }
            });
    }

    function trackJobsSearched(context) {
        const jobSearchInput = document.querySelector("[segment-job-search]");

        if (jobSearchInput) {
            let typingTimer;
            let doneTypingInterval = 800;
            let previousQuery;

            jobSearchInput.addEventListener("keyup", () => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                    const query = jobSearchInput.value.trim();

                    if (query.length > 1 && query != previousQuery) {
                        analytics.track(
                            "Jobs Searched",
                            { query },
                            { context }
                        );
                    }
                    previousQuery = query;
                }, doneTypingInterval);
            });

            jobSearchInput.addEventListener("keydown", () => {
                clearTimeout(typingTimer);
            });
        }
    }

    function trackEnquiryListeners(event, props, context) {
        const telEls = document.querySelectorAll('a[href^="tel"]');
        const emailEls = document.querySelectorAll('a[href^="mailto"]');
        const portalEls = document.querySelectorAll(
            'a[href*="kandidatenportal"]'
        );

        const enquiryEls = [
            ...[...telEls].map((el) => [el, "phone"]),
            ...[...emailEls].map((el) => [el, "email"]),
            ...[...portalEls].map((el) => [el, "form"]),
        ];

        enquiryEls.forEach(([el, lead_type]) => {
            el.addEventListener("click", () => {
                const enquiryProps = { ...props, lead_type };

                if (
                    !Object.entries(enquiryProps).some(
                        ([_, value]) => value === ""
                    )
                ) {
                    analytics.track(event, enquiryProps, { context });
                }
            });
        });
    }

    function trackJobEvents(context) {
        if (window.location.pathname.includes("/jobs/")) {
            const jobAttrProps = getAttrProps(document, [
                "job_title",
                "job_id",
                "job_location",
            ]);
            const jobUrlProps = getJobIdLanguage(window.location.pathname);
            const jobProps = { ...jobAttrProps, ...jobUrlProps };

            if (!Object.entries(jobProps).some(([_, value]) => value === "")) {
                analytics.track("Job Viewed", jobProps, { context });
                trackEnquiryListeners("Job Enquired", jobProps, context);
            }
        }
    }

    function trackSiteSearch(context) {
        const searchInput = document.querySelector(
            'form.search input[type="search"]'
        );

        if (searchInput) {
            let typingTimer;
            let doneTypingInterval = 800;
            let previousQuery;

            searchInput.addEventListener("keyup", () => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                    const query = searchInput.value.trim();

                    if (query.length > 1 && query != previousQuery) {
                        analytics.track(
                            "Site Searched",
                            { query },
                            { context }
                        );
                    }
                    previousQuery = query;
                }, doneTypingInterval);
            });

            searchInput.addEventListener("keydown", () => {
                clearTimeout(typingTimer);
            });
        }
    }

    function trackContactFormOnSubmit(contactForm, context, formType) {
        const targetProps = {
            name: "name",
            "first name": "first_name",
            "last name": "last_name",
            email: "email",
            "phone number": "phone",
            "company name": "company_name",
        };

        contactForm.addEventListener("submit", () => {
            let traits = {};

            contactForm
                .querySelectorAll("input[data-name]")
                .forEach((field) => {
                    const fieldName = field.dataset.name.toLowerCase();

                    if (Object.keys(targetProps).includes(fieldName)) {
                        const value = field.value;

                        if (fieldName == "name") {
                            traits = getFirstLastName(traits, value);
                        } else {
                            traits[targetProps[fieldName]] = value;
                        }
                    }
                });

            traits["lead_type"] = "form";

            context = addUrlTagsToContext(context);

            analytics.identify(traits, { context });

            if (formType === "contact") {
                analytics.track("Lead Submitted", traits, { context });
            } else if (formType === "nurture") {
                analytics.track("Nurturing Form Submitted", traits, {
                    context,
                });
            }
        });
    }

    function addFormHiddenFields(form) {
        const dataTracking = form.dataset.tracking;
        const user = analytics.user ? analytics.user() : null;

        let values = {
            URL: window.location.origin + window.location.pathname,
            Query: window.location.search,
            "Segment User ID": user ? user.id() : "",
            "Segment Anonymous ID": user ? user.anonymousId() : "",
            "Current Page": pageTitleCleaned,
            Referrer: document.referrer,
            Tracking: dataTracking,
            "User Agent": navigator.userAgent,
            "Idempotency Token": getIdempotencyToken(),
            "Has Segment ID": "Yes",
        };

        if (!user) {
            values["Has Segment ID"] = "No";
        }

        values = getUrlTagsHiddenFields(values);

        Object.entries(values).forEach(([name, value]) =>
            appendInputFieldToForm(form, name, value)
        );
    }

    function initContactFormTrackers(context) {
        analytics &&
            analytics.ready(function () {
                const contactForms = document.querySelectorAll(
                    'form[data-tracking="contact-form"]'
                );

                contactForms.forEach((contactForm) => {
                    if (contactForm) {
                        addFormHiddenFields(contactForm);
                        trackContactFormOnSubmit(
                            contactForm,
                            context,
                            "contact"
                        );
                    }
                });

                const nurtureForms = document.querySelectorAll(
                    'form[data-tracking="nurturing-form"]'
                );

                nurtureForms.forEach((nurtureForm) => {
                    if (nurtureForm) {
                        addFormHiddenFields(nurtureForm);
                        trackContactFormOnSubmit(
                            nurtureForm,
                            context,
                            "nurture"
                        );
                    }
                });
            });
    }

    return {
        getDefaultContext,
        trackEvent,
        trackClicks,
        trackStartAnalysisEvent,
        trackJobEvents,
        trackJobsSearched,
        trackPrelimFormViewed,
        trackSiteSearch,
        initContactFormTrackers,
        trackEnquiryListeners,
    };
})();