document.addEventListener("DOMContentLoaded", () => {
    const targetPages = [
        "/candidates",
        "/jobs",
        "/karriere",
        "/de-jobs",
        "/kandidaten",
    ];

    if (targetPages.some((page) => document.location.pathname.includes(page))) {
        JobPostingSchema.addJobPostingJsonLd();
    }
});

const JobPostingSchema = (function() {
    const today = new Date();
    const project = getCtgProject();
    const countries = {
        DE: "Germany",
        CH: "Switzerland",
        GB: "United Kingdom",
        FR: "France",
    };

    function getDatePosted(dateStr) {
        const inputDate = new Date(dateStr);

        let oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(today.getMonth() - 1);

        if (inputDate < oneMonthAgo) return oneMonthAgo.toISOString().split("T")[0];
        
        return dateStr;
    }

    function getOrganization() {
        if (typeof project !== 'undefined' && project) {
            return project[1][0];
        }

        return "CareerTeam";
    }

    function getCountry() {
        if (typeof project !== "undefined" && project) {
            return project[1][1];
        }

        return "DE";
    }

    function isFullRemote() {
        return document.body.innerText.toLowerCase().includes("100% remote");
    }

    function getJobDescription() {
        const jobDescriptionDoms = document.querySelectorAll("[job-description]");
        let jobDescription = "";

        jobDescriptionDoms.forEach(description => {
            jobDescription += `<p>${description.innerHTML}</p>`;
        });

        return jobDescription;
    }

    function addJobPostingJsonLd() {
        const jobTitleDom = document.querySelector("[job-title]");
        const datePostedDom = document.querySelector("[job-date-posted]");
        const employmentTypeDom = document.querySelector("[job-occupation-type]");
        const locationDom = document.querySelector("[job-location]");
        const countryCode = getCountry();

        const jsonLdContent = {
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            title: jobTitleDom ? jobTitleDom.innerText : "Job post",
            description: getJobDescription(),
            datePosted: datePostedDom
                ? getDatePosted(datePostedDom.innerText)
                : getDatePosted("2023-01-01"),
            employmentType: employmentTypeDom
                ? employmentTypeDom.innerText
                : "",
            hiringOrganization: {
                "@type": "Organization",
                name: getOrganization(),
                sameAs: window.location.origin,
                logo: document.querySelector(
                    ".nav_brand-image, .brand-img, .logo"
                ).src,
            },
            jobLocation: {
                "@type": "Place",
                address: {
                    "@type": "PostalAddress",
                    addressLocality: locationDom ? locationDom.innerText : "",
                    addressCountry: countryCode,
                },
            },
        };

        if (isFullRemote()) {
            jsonLdContent["applicantLocationRequirements"] = {
                "@type": "Country",
                name: countries[countryCode],
            };
            jsonLdContent["jobLocationType"] = "TELECOMMUTE";
        }

        const script = document.createElement("script");
        script.type = "application/ld+json";

        script.innerHTML = JSON.stringify(jsonLdContent);
        document.head.appendChild(script);
    }

    return {
        addJobPostingJsonLd,
    };
})();