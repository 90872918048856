const JOTFORM_BRANDS = [
    {
        url: "paltron.com/en/preliminary-information",
        region: "GB",
        jotformId: "212900612311035",
        sessionStr: "PAL-en",
    },
    {
        url: "paltron.com/vorabinformationen",
        region: "DE",
        jotformId: "212901253788459",
        sessionStr: "PAL-de",
    },
    {
        url: "careerteam.de/en/preliminary-information",
        region: "GB",
        jotformId: "220147679219461",
        sessionStr: "CAR-en",
    },
    {
        url: "careerteam.de/vorabinformationen",
        region: "DE",
        jotformId: "220147376108452",
        sessionStr: "CAR-de",
    },
    {
        url: "careerteam.ch/ch/vorabinformationen",
        region: "CH",
        jotformId: "221067075686461",
        sessionStr: "CAR-ch",
    },
    {
        url: "careerteam.ch/ch/preliminary-information",
        region: "GB",
        jotformId: "221662108359860",
        sessionStr: "CAR-en",
    },
    {
        url: "benomik.com/en/preliminary-information",
        region: "GB",
        jotformId: "220147161927453",
        sessionStr: "BEN-en",
    },
    {
        url: "benomik.com/vorabinformationen",
        region: "DE",
        jotformId: "220147701807450",
        sessionStr: "BEN-de",
    },
    {
        url: "ctg-consulting.com/vorabinformationen",
        region: "DE",
        jotformId: "222433434226347",
        sessionStr: "CTG-de",
    },
    {
        url: "webflow.io/",
        region: "DE",
        jotformId: "220462058466052",
        sessionStr: "Webflow-de",
    },
];

const URL_TAGS = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "gclid",
    "msclid",
    "msclkid",
];