document.addEventListener("DOMContentLoaded", (event) => {
    const {
        updateHeaderByKeyword,
        initPhoneNumber,
        autoPopulateFilter,
        showEmailIdentifyCandidates,
        appendAnonymousIdToInternalLinks,
        storeUrlTagsToCookies,
        initMailToLinkHref,
        initJobFilters,
    } = WebflowCustomCode;

    const jobSearchForm = document.querySelector(
        '[data-name="Job Search Form"]'
    );

    if (jobSearchForm) {
        const targetFilters = [
            "Location",
            "Level",
            "Area",
            "Skills",
            "Type",
            "Department",
        ];

        targetFilters.forEach((target) => autoPopulateFilter(target));
    }

    updateHeaderByKeyword();
    initPhoneNumber();
    storeUrlTagsToCookies();
    initMailToLinkHref();
    showEmailIdentifyCandidates();  
    appendAnonymousIdToInternalLinks();
    initJobFilters();
});

const WebflowCustomCode = (function () {
    const pageTitleCleaned = document.title.split("|")[0].trim();

    function addIntlTelInputLibraryScript() {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.integrity =
            "sha512-hpJ6J4jGqnovQ5g1J39VtNq1/UPsaFjjR6tuCVVkKtFIx8Uy4GeixgIxHPSG72lRUYx1xg/Em+dsqxvKNwyrSg==";
        script.src =
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/intlTelInput.min.js";
        script.crossOrigin = "anonymous";
        script.referrerPolicy = "no-referrer";

        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    }

    function autoPopulateFilter(filterId) {
        const filterField = document.getElementById(filterId);

        if (filterField) {
            const toFilterItems = document.querySelectorAll(
                `.${filterId.toLowerCase()}-filter`
            );

            let uniqueItems = [...toFilterItems]
                .map((el) => el.innerText.trim())
                .join(",")
                .split(/[,(\/]|\sor\s/)
                .map((value) => value.replace(/[)]/, "").trim())
                .filter(
                    (target, index, arr) =>
                        arr.indexOf(target) === index && target != ""
                )
                .sort();

            let jobFilterSelect = document.querySelector(
                `[data-name="${filterId}"]`
            );

            if (jobFilterSelect) {
                removeExistingOptions(jobFilterSelect);
                addOption("", getAllTextTranslation(), jobFilterSelect);

                uniqueItems.forEach((target) => {
                    addOption(target, target, jobFilterSelect);
                });
            }
        }
    }

    function removeExistingOptions(select) {
        const length = select.options.length;

        for (i = length - 1; i >= 0; i--) {
            select.options[i] = null;
        }
    }

    function addOption(value, text, select) {
        let option = document.createElement("option");

        option.value = value;
        option.text = text;

        select.add(option);
    }

    function getAllTextTranslation() {
        const allTextTranslationMap = {
            en: "All",
            de: "Alle anzeigen",
            ch: "Alle anzeigen",
            fr: "Afficher tout",
        };

        return language && allTextTranslationMap[language]
            ? allTextTranslationMap[language]
            : "All";
    }

    function updateHeaderByKeyword() {
        const headerKeywordElem = document.querySelector(
            'h1[data-name="keyword"]'
        );

        if (headerKeywordElem) {
            const utmTerm = getParamFromUrl("utm_term");

            if (utmTerm !== "") {
                document.querySelector('h1[data-name="keyword"]').innerHTML =
                    utmTerm;
            }
        }
    }

    function initPhoneNumber() {
        const phoneInputs = document.querySelectorAll(
            '[data-name="Phone Number"]'
        );

        const errorMap = {
            gb: [
                "Invalid number",
                "Invalid country code",
                "Number is too short",
                "Number is too long",
                "Invalid number",
            ],
            de: [
                "Ungültige Nummer",
                "Ungültiger Ländercode",
                "Nummer ist zu kurz",
                "Nummer ist zu lang",
                "Ungültige Nummer",
            ],
            fr: [
                "Numéro invalide",
                "Code pays invalide",
                "Le numéro est trop court",
                "Le numéro est trop long",
                "Numéro invalide",
            ],
            ch: [
                "Ungültige Nummer",
                "Ungültiger Ländercode",
                "Nummer ist zu kurz",
                "Nummer ist zu lang",
                "Ungültige Nummer",
            ],
        };

        phoneInputs.forEach((input) => {
            if (!input) return;

            setFormPhoneValidationSettings(input);
            createErrorDivIfNotExists(input);
            createValidDivIfNotExists(input);

            const form = input.closest("form");

            var errorMsg = form.querySelector('[data-name="error"]'),
                validMsg = form.querySelector('[data-name="valid"]');

            //Follow ISO 3166-1 alpha-2 codes https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
            var dataLang = input.dataset.lang;

            var hasErrors = false;

            // initialise plugin
            var iti = window.intlTelInput(input, {
                nationalMode: true,
                initialCountry: "auto",
                geoIpLookup: function (success, failure) {
                    if (dataLang) {
                        success(dataLang);
                    } else {
                        success("de");
                    }
                },
                utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
            });

            var reset = function () {
                input.classList.remove("phone-input_error");
                errorMsg.innerHTML = "";
                errorMsg.classList.add("phone-input_element-hide");
                validMsg.classList.add("phone-input_element-hide");
            };

            // on blur: validate
            input.addEventListener("blur", function () {
                reset();
                if (iti.isValidNumber() || input.value.trim() == "") {
                    hasErrors = false;

                    validMsg.classList.remove("phone-input_element-hide");

                    if (input.value.trim() != "") {
                        //Assign national mode phone number to phone input field
                        input.value = iti.getNumber();
                    }
                } else {
                    hasErrors = true;
                    input.classList.add("phone-input_error");
                    var errorCode = iti.getValidationError();
                    errorCode = errorCode == -99 ? 0 : errorCode;

                    errorMsg.innerHTML = errorMap[dataLang][errorCode];
                    errorMsg.classList.remove("phone-input_element-hide");
                }
            });

            // on keyup / change flag: reset
            input.addEventListener("change", reset);
            input.addEventListener("keyup", reset);

            //Prevent form to submit if there is error.
            form.querySelector("input[type=submit]").onclick = function (e) {
                if (hasErrors) {
                    e.preventDefault();
                } else {
                    return null;
                }
            };
        });
    }

    function createErrorDiv() {
        const div = document.createElement("div");

        div.classList.add("phone-input_warning");
        div.classList.add("phone-input_element-hide");

        div.dataset.name = "error";
        div.setAttribute("id", "error-msg");

        return div;
    }

    function createPhoneValidDiv() {
        const div = document.createElement("div");

        div.classList.add("phone-input_valid-icon");
        div.classList.add("phone-input_element-hide");
        div.classList.add("w-embed");

        div.dataset.name = "valid";

        const svg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
        );

        svg.setAttribute("width", "100%");
        svg.setAttribute("height", "100%");
        svg.setAttribute("viewBox", "0 0 16 16");
        svg.setAttribute("fill", "none");

        const circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
        );

        circle.setAttribute("cx", "50%");
        circle.setAttribute("cy", "50%");
        circle.setAttribute("r", "50%");
        circle.setAttribute("fill", "#33B497");

        const path = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
        );

        path.setAttribute(
            "d",
            "M6.92168 10.6673C6.81088 10.6673 6.70931 10.6263 6.6262 10.5341L4.78872 8.49505C4.62251 8.31061 4.62251 8.02371 4.78872 7.83927C4.95492 7.65483 5.21346 7.65483 5.37967 7.83927L6.93091 9.55044L10.6151 5.47231C10.7813 5.28787 11.0399 5.28787 11.2061 5.47231C11.3723 5.65675 11.3723 5.94365 11.2061 6.12809L7.22639 10.5341C7.13405 10.6263 7.03248 10.6673 6.92168 10.6673Z"
        );
        path.setAttribute("fill", "white");

        svg.appendChild(circle);
        svg.appendChild(path);
        div.appendChild(svg);

        return div;
    }

    function createErrorDivIfNotExists(phoneInput) {
        if (!phoneInput.closest("form").querySelector('[data-name="error"]')) {
            phoneInput.parentNode.appendChild(createErrorDiv());
        }
    }

    function createValidDivIfNotExists(phoneInput) {
        if (!phoneInput.closest("form").querySelector('[data-name="valid"]')) {
            phoneInput.parentNode.appendChild(createPhoneValidDiv());
        }
    }

    function setFormPhoneValidationSettings(phoneInput) {
        phoneInput.closest("form").dataset.settings = "phone-validation";
    }

    function showEmailIdentifyCandidates() {
        const emails = document.querySelectorAll('[data-name="Email"]');

        emails.forEach((el) => {
            el.addEventListener("keyup", () => {
                const value = el.value;
                const nonCompanyEmailRegex =
                    /[a-zA-Z0-9_\\.-]+@((hotmail)|(yahoo)|(gmail)|(outlook)|(icloud)|(gmx)|(web)|(mail)|(freenet)|(email))[.][a-z]{2,4}$/g;
                const emailWarningEl = el.parentNode.querySelector(
                    "[data-email-warning]"
                );

                if (!emailWarningEl) return;

                if (value.match(nonCompanyEmailRegex)) {
                    emailWarningEl.style.height = "auto";
                } else {
                    emailWarningEl.style.height = 0;
                }
            });
        });
    }

    function getProdDomainAttributeSelectors() {
        const prodDomains = Object.keys(PROD_DOMAINS);

        return prodDomains.map((domain) => `a[href*="${domain}"]`).join(",");
    }

    function appendAnonymousIdToInternalLinks() {
        const excludeLinks = ["mailto", "tel", "kandidatenportal"];
        let internalLinks = document.querySelectorAll(
            getProdDomainAttributeSelectors()
        );

        internalLinks.forEach((link) => {
            const href = link.getAttribute("href");

            if (
                !excludeLinks.some((excludeLink) => href.includes(excludeLink))
            ) {
                let internalLink = new URL(href);
                internalLink.searchParams.set(
                    "ajs_aid",
                    analytics.user ? analytics.user().anonymousId() : ""
                );

                link.setAttribute(
                    "href",
                    internalLink.href.replace(/\+/g, "%20")
                );
            }
        });
    }

    function storeUrlTagsToCookies() {
        URL_TAGS.forEach((tag) => {
            const tagValue = getParamFromUrl(tag);

            if (tagValue !== "") {
                cookieHandler().setCookie(tag, tagValue, 1);
            }
        });
    }

    function appendInputFieldToForm(contactForm, name, value) {
        const hiddenInput = document.createElement("input");

        hiddenInput.type = "hidden";
        hiddenInput.name = name;
        hiddenInput.value = value;

        contactForm.appendChild(hiddenInput);
    }

    function initMailToLinkHref() {
        let mailToLinks = document.querySelectorAll('a[href^="mailto:"]');

        mailToLinks.forEach((link) => {
            const href = link.getAttribute("href");
            let email = new URL(href);
            email.searchParams.set("subject", "Enquiry: " + pageTitleCleaned);

            link.setAttribute("href", email.href.replace(/\+/g, "%20"));
        });
    }

    function initJobFilters() {
        const jobListDom = document.querySelector(".jobs-list");

        if (!jobListDom) return;

        const jobsFeed = new FsLibrary(".jobs-list");

        const myFilters = [
            {
                filterWrapper: ".jobs-filter-bar",
                filterType: "multi",
            },
            {
                filterWrapper: ".jobs-skills-bar",
                filterType: "multi",
                filterByClass: ".skills-filter",
            },
            {
                filterWrapper: ".jobs-location-bar",
                filterType: "multi",
                filterByClass: ".location-filter",
            },
            {
                filterWrapper: ".jobs-category-bar",
                filterType: "multi",
                filterByClass: ".category-filter",
            },
            {
                filterWrapper: ".jobs-type-bar",
                filterType: "multi",
                filterByClass: ".type-filter",
            },
            {
                filterWrapper: ".jobs-department-bar",
                filterType: "multi",
                filterByClass: ".department-filter",
            },
            {
                filterWrapper: ".jobs-lang-bar",
                filterType: "multi",
                filterByClass: ".lang-filter",
            },
            {
                filterWrapper: ".jobs-level-bar",
                filterType: "multi",
                filterByClass: ".level-filter",
            },
            {
                filterWrapper: ".jobs-area-bar",
                filterType: "multi",
                filterByClass: ".area-filter",
            },
        ];

        jobsFeed.filter({
            filterArray: myFilters,
            activeClass: "button-active",
            animation: {
                enable: true,
            },
        });
    }

    return {
        updateHeaderByKeyword,
        initPhoneNumber,
        autoPopulateFilter,
        addIntlTelInputLibraryScript,
        showEmailIdentifyCandidates,
        appendAnonymousIdToInternalLinks,
        storeUrlTagsToCookies,
        initMailToLinkHref,
        initJobFilters,
    };
})();

WebflowCustomCode.addIntlTelInputLibraryScript();
