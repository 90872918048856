document.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector(".jotform-iframe")) {
        JotformCustomCode.initializeJotform();
    }
});

const JotformCustomCode = (function () {
    const shareableLink = {
        GB: {
            link: "Shareable Link",
            copy: "Copy",
            copied: "Copied!",
        },
        DE: {
            link: "Teilbarer Link",
            copy: "Kopieren",
            copied: "Kopiert!",
        },
        CH: {
            link: "Teilbarer Link",
            copy: "Kopieren",
            copied: "Kopiert!",
        },
    };

    let brandInfo;
    let jotformIframeId;
    let shareableLinkTexts;

    function setBrandInfo() {
        const currentSiteUrl =
            window.location.hostname + window.location.pathname;

        brandInfo = getBrandInfo(currentSiteUrl);
    }

    function getBrandInfo(siteUrl) {
        try {
            const brandInfo =
                typeof currentBrand !== "undefined" && currentBrand
                    ? currentBrand
                    : JOTFORM_BRANDS.find((el) => siteUrl.includes(el.url));

            if (!brandInfo) return null;

            const shareableLinkTexts = shareableLink[brandInfo.region];

            if (!("sessionStr" in brandInfo)) {
                if ("name" in brandInfo) {
                    brandInfo["sessionStr"] = `${brandInfo["name"]}-${brandInfo[
                        "region"
                    ].toLowerCase()}`;
                } else {
                    brandInfo["sessionStr"] = `${
                        brandInfo["jotformId"]
                    }-${brandInfo["region"].toLowerCase()}`;
                }
            }

            return { ...brandInfo, shareableLinkTexts };
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    function initIframe() {
        let targetIframe = document.getElementsByClassName("jotform-iframe")[0];
        const anonymousId = analytics.user().anonymousId();

        targetIframe.setAttribute("id", jotformIframeId);
        targetIframe.setAttribute(
            "src",
            `https://form.jotform.com/${brandInfo.jotformId}?anonymousId=${anonymousId}`
        );
    }

    function addSessionKeys() {
        const key = "session";
        const date = new Date();
        const uniqid = date.getTimezoneOffset() + "-" + date.getTime();
        const session = brandInfo.sessionStr + uniqid;
        const stoken = "token" + uniqid;
        const jotformId = brandInfo.jotformId;

        if (getParamFromUrl("session") == "") {
            document.location.search = `session=${session}&stoken=${stoken}&jid=${jotformId}`;
        } else if (
            getParamFromUrl("session") != "" &&
            getParamFromUrl("jid") == ""
        ) {
            document.location.search += `&jid=${jotformId}`;
        }
    }

    function doJotformCode() {
        let ifr = document.getElementById(jotformIframeId);
        if (ifr) {
            let src = ifr.src;
            let iframeParams = [];
            if (
                window.location.href &&
                window.location.href.indexOf("?") > -1
            ) {
                iframeParams = iframeParams.concat(
                    window.location.href
                        .substr(window.location.href.indexOf("?") + 1)
                        .split("&")
                );
            }
            if (src && src.indexOf("?") > -1) {
                iframeParams = iframeParams.concat(
                    src.substr(src.indexOf("?") + 1).split("&")
                );
                src = src.substr(0, src.indexOf("?"));
            }
            iframeParams.push("isIframeEmbed=1");
            ifr.src = src + "?" + iframeParams.join("&");
        }
        window.handleIFrameMessage = function (e) {
            if (typeof e.data === "object") {
                return;
            }
            let args = e.data.split(":");
            if (args.length > 2) {
                iframe = document.getElementById(
                    "JotFormIFrame-" + args[args.length - 1]
                );
            } else {
                iframe = document.getElementById("JotFormIFrame");
            }
            if (!iframe) {
                return;
            }
            switch (args[0]) {
                case "scrollIntoView":
                    iframe.scrollIntoView();
                    break;
                case "setHeight":
                    iframe.style.height = args[1] + "px";
                    break;
                case "collapseErrorPage":
                    if (iframe.clientHeight > window.innerHeight) {
                        iframe.style.height = window.innerHeight + "px";
                    }
                    break;
                case "reloadPage":
                    window.location.reload();
                    break;
                case "loadScript":
                    if (
                        !window.isPermitted(e.origin, [
                            "jotform.com",
                            "jotform.pro",
                        ])
                    ) {
                        break;
                    }
                    let src = args[1];
                    if (args.length > 3) {
                        src = args[1] + ":" + args[2];
                    }
                    let script = document.createElement("script");
                    script.src = src;
                    script.type = "text/javascript";
                    document.body.appendChild(script);
                    break;
                case "exitFullscreen":
                    if (window.document.exitFullscreen)
                        window.document.exitFullscreen();
                    else if (window.document.mozCancelFullScreen)
                        window.document.mozCancelFullScreen();
                    else if (window.document.mozCancelFullscreen)
                        window.document.mozCancelFullScreen();
                    else if (window.document.webkitExitFullscreen)
                        window.document.webkitExitFullscreen();
                    else if (window.document.msExitFullscreen)
                        window.document.msExitFullscreen();
                    break;
            }
            const isJotForm = e.origin.indexOf("jotform") > -1 ? true : false;
            if (
                isJotForm &&
                "contentWindow" in iframe &&
                "postMessage" in iframe.contentWindow
            ) {
                const urls = {
                    docurl: encodeURIComponent(document.URL),
                    referrer: encodeURIComponent(document.referrer),
                };
                iframe.contentWindow.postMessage(
                    JSON.stringify({ type: "urls", value: urls }),
                    "*"
                );
            }
        };
        window.isPermitted = function (originUrl, whitelisted_domains) {
            let url = document.createElement("a");
            url.href = originUrl;
            let hostname = url.hostname;
            let result = false;
            if (typeof hostname !== "undefined") {
                whitelisted_domains.forEach(function (element) {
                    if (
                        hostname.slice(-1 * element.length - 1) ===
                            ".".concat(element) ||
                        hostname === element
                    ) {
                        result = true;
                    }
                });
                return result;
            }
        };

        if (window.addEventListener) {
            window.addEventListener("message", handleIFrameMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent("onmessage", handleIFrameMessage);
        }

        if (
            ifr &&
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            ifr.setAttribute("scrolling", "no");
        }
    }

    function initShareableLink() {
        const jotformIframe = document.getElementById(jotformIframeId);
        const shareableLinkContainer = document.querySelector('.shareable-link-container');

        shareableLinkContainer && shareableLinkContainer.remove();

        if (jotformIframe) {
            let parentElem = jotformIframe.parentNode;
            const shareableLinkElem = createShareableLinkHtml();

            parentElem.insertBefore(shareableLinkElem, jotformIframe);
        }
    }

    // sl shortcut for shareable link
    function createShareableLinkHtml() {
        let slElem = document.createElement("div");
        slElem.className = "shareable-link-container";

        let slButtonTitle = document.createElement("button");
        slButtonTitle.className = "shareable-link-btn-container";
        slButtonTitle.id = "slink";

        let slButtonTitleText = document.createTextNode(
            shareableLinkTexts.link
        );
        slButtonTitle.appendChild(slButtonTitleText);

        slElem.appendChild(slButtonTitle);

        let slLinkInput = document.createElement("input");
        slLinkInput.type = "text";
        slLinkInput.id = "shareable_link";
        slLinkInput.value = window.location.href;
        slLinkInput.readOnly = true;

        slElem.appendChild(slLinkInput);

        let slButtonCopy = document.createElement("button");
        slButtonCopy.className = "shareable-link-btn-container";
        slButtonCopy.onclick = function () {
            copyToClipboard();
        };
        slButtonCopy.id = "copy-link";

        let slButtonCopyText = document.createTextNode(shareableLinkTexts.copy);
        slButtonCopy.appendChild(slButtonCopyText);

        slElem.appendChild(slButtonCopy);

        return slElem;
    }

    function copyToClipboard() {
        let copyText = document.getElementById("shareable_link");

        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(copyText.value);

        document.getElementById("copy-link").innerHTML =
            shareableLinkTexts.copied;

        setTimeout(function () {
            document.getElementById("copy-link").innerHTML =
                shareableLinkTexts.copy;
        }, 1500);
    }

    function showPrelimFormFallbackOption() {
        const checkTimer = 4000;

        setTimeout(() => {
            const targetIframe =
                document.getElementsByClassName("jotform-iframe")[0];
            const noscriptElem = document.querySelector(
                "noscript#jotform-noscript"
            );

            if (!targetIframe.src && noscriptElem) {
                let iframeContainer =
                    document.getElementsByClassName("w-iframe")[0];

                const noscriptMsgElem = new DOMParser().parseFromString(
                    noscriptElem.innerText,
                    "text/xml"
                ).documentElement;
                const messageContainer = document.createElement("div");
                messageContainer.style = "font-weight: bold";
                messageContainer.innerHTML = noscriptMsgElem.innerHTML;

                iframeContainer.prepend(messageContainer);
            }
        }, checkTimer);
    }

    function initializeJotform() {
        setBrandInfo();

        if (brandInfo) {
            jotformIframeId = `JotFormIFrame-${brandInfo.jotformId}`;
            shareableLinkTexts = brandInfo.shareableLinkTexts;

            analytics &&
                analytics.ready(function () {
                    initIframe();
                    addSessionKeys();
                    doJotformCode();
                });

            showPrelimFormFallbackOption();
        }
    }

    return {
        initializeJotform,
    };
})();
