document.addEventListener("DOMContentLoaded", () => {
    if (window.location.host.includes("findusfinds")) {
        Findus.setAutoCompleteToSkillInputs();
        Findus.showHideField();
    }
});

const Findus = (function () {
    function initNavButtonsEventListeners() {
        const navButtons = document.querySelectorAll(".q-form_navigation a");

        navButtons.forEach((a) => {
            a.addEventListener("click", () => {
                hideFirstPrevButton();
            });
        });
    }

    function hideFirstPrevButton() {
        const prevButton = document.querySelector('[data-form="nav-prev"]');
        const firstSlide = document.querySelectorAll(".q-form_slide")[0];

        if (
            firstSlide.hasAttribute("style") &&
            firstSlide.style.visibility == ""
        ) {
            prevButton.style.visibility = "hidden";
        } else {
            prevButton.style.visibility = "visible";
        }
    }

    function showHideField() {
        const findusShowHideField = document.querySelector(
            "[findus-show-hide-field]"
        );
        const checkbox = document.querySelector("[findus-show-hide-checkbox]");

        if (!findusShowHideField || !checkbox) return;

        const parentWrapper = document.querySelector(
            "[findus-show-hide-field]"
        ).parentNode;

        checkbox.addEventListener("change", (event) => {
            if (event.target.checked) {
                parentWrapper.style.visibility = "hidden";
            } else {
                parentWrapper.style.visibility = "visible";
            }
        });
    }

    function triggerNextPrevButtons() {
        const navNext = document.querySelectorAll('[data-form="nav-next"]');
        const navPrev = document.querySelectorAll('[data-form="nav-prev"]');

        navNext.forEach((elem) => {
            elem.addEventListener("click", () => {
                document.querySelector('[data-qform="arrow-next"]').click();
            });
        });

        navPrev.forEach((elem) => {
            elem.addEventListener("click", () => {
                document.querySelector('[data-qform="arrow-prev"]').click();
            });
        });
    }

    async function getSkills(input) {
        const xhr = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            xhr.open(
                "POST",
                "https://2glef4hprl7yjgru4ejae2g2sy0dctnm.lambda-url.eu-central-1.on.aws/",
                true
            );
            xhr.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );

            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText,
                    });
                }
            };

            xhr.onerror = function () {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            };

            xhr.send(`skill=${input}`);
        });
    }

    function initAutoComplete(skillInput) {
        const inputIdentifier = skillInput.getAttribute("name");

        const autoCompleteJS = new autoComplete({
            selector: `[name="${inputIdentifier}"]`,
            debounce: 300,
            data: {
                src: async (query) => {
                    const value = query;
                    const response = await getSkills(query);
                    let skills;

                    if (response) {
                        skills = JSON.parse(response);

                        if ("skills" in skills && skills["skills"]) {
                            return skills["skills"].map(
                                (skill) => skill["description"]
                            );
                        }
                    }

                    return [];
                },
                cache: false,
                filter: (list) => {
                    // Filter duplicates
                    // incase of multiple data keys usage
                    const filteredResults = Array.from(
                        new Set(list.map((value) => value.match))
                    ).map((skill) => {
                        return list.find((value) => value.match === skill);
                    });

                    return filteredResults;
                },
            },
            placeHolder: "",
            resultsList: {
                noResults: false,
                maxResults: 8,
                tabSelect: true,
            },
            resultItem: {
                element: (item, data) => {
                    // Modify Results Item Style
                    item.style =
                        "display: flex; justify-content: space-between;";
                    // Modify Results Item Content
                    item.innerHTML = `
                <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                ${data.match}
                </span>
                <span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);">
                
                </span>`;
                },
                highlight: false,
            },
            events: {
                input: {
                    focus() {
                        if (autoCompleteJS.input.value.length)
                            autoCompleteJS.start();
                    },

                    selection(event) {
                        const feedback = event.detail;
                        autoCompleteJS.input.blur();

                        const selection = feedback.selection.value;
                        autoCompleteJS.input.value = selection;
                    },
                },
            },
        });
    }

    function setAutoCompleteToSkillInputs() {
        const skillInputs = document.querySelectorAll(
            "[data-textkernel-autocomplete]"
        );

        let hasLoadedRecord = [];

        skillInputs.forEach((input, index) => {
            hasLoadedRecord[index] = false;

            initAutoComplete(input);

            input.addEventListener("input", function () {
                const autoCompleteListElem = document.getElementById(
                    `autoComplete_list_${index + 1}`
                );

                autoCompleteListElem.style.top =
                    input.offsetTop + input.offsetHeight + "px";

                if (autoCompleteListElem.hasAttribute("hidden")) {
                    autoCompleteListElem.removeAttribute("hidden");

                    if (!hasLoadedRecord[index]) {
                        const loadingLi = document.createElement("li");
                        const loaderDiv = document.createElement("div");

                        loaderDiv.className = "loader";

                        loadingLi.appendChild(loaderDiv);

                        autoCompleteListElem.appendChild(loadingLi);
                        hasLoadedRecord[index] = true;
                    }
                }
            });
        });
    }

    return {
        setAutoCompleteToSkillInputs,
        showHideField,
    };
})();