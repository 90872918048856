document.addEventListener("DOMContentLoaded", (event) => {
    const {
        displaySalaryResult,
        setPrivacyPolicyCheckbox,
        addLanguageResponseField,
        initConsultationRequestedEvent,
        addTrackingFormHiddenFields,
    } = SalaryGenerator;

    if (
        window.location.pathname ==
            "/tools/ai/salary-range-generator-results" ||
        window.location.pathname ==
            "/tools/ai/en-salary-range-generator-results"
    ) {
        displaySalaryResult();
        addTrackingFormHiddenFields();
        initConsultationRequestedEvent();
    } else if (
        window.location.pathname == "/tools/ai/salary-range-generator" ||
        window.location.pathname == "/tools/ai/en-salary-range-generator"
    ) {
        addLanguageResponseField();
        setPrivacyPolicyCheckbox();
        initConsultationRequestedEvent();
        addTrackingFormHiddenFields();
    }
});

const SalaryGenerator = (function () {
    function getUrlTagsHiddenFields(hiddenFieldsValues) {
        URL_TAGS.forEach((tag) => {
            const tagValue = cookieHandler().getCookie(tag);

            if (tagValue !== "") {
                hiddenFieldsValues[tag] = tagValue;
            }
        });

        return hiddenFieldsValues;
    }
    
    function addTrackingFormHiddenFields() {
        analytics && analytics.ready(function () {
            const salaryForm = document.querySelector(
                'form[data-name="Salary Range Form"], [data-name="Salary Range Consultation Form"]'
            );

            const user = analytics.user ? analytics.user() : null;

            if (!salaryForm) return;

            let values = {
                url: window.location.origin + window.location.pathname,
                anonymous_id: user ? user.anonymousId() : "",
                current_page: document.title.split("|")[0].trim(),
                Tracking: "Salary Range Form",
            };

            values = getUrlTagsHiddenFields(values);

            Object.entries(values).forEach(([name, value]) =>
                appendInputFieldToForm(salaryForm, name, value)
            );
        });
    }
    
    function hideSplashScreen() {
        const splasScreen = document.querySelector(".tools_ai1_splash-screen");

        if (!tl || !splasScreen) return;

        tl.duration(1);

        setTimeout(() => {
            splasScreen.classList.add("hidden");
        }, 1250);

        setTimeout(() => {
            splasScreen.style.display = "none";
        }, 1500);
    }

    function getFeedbackUI(descFeedback) {
        const descFeedbackDom = {
            htmlDom: "ul",
            className: "tools_ai1_results_list",
            children: [],
        };

        const feedbacks = JSON.parse(descFeedback);

        feedbacks.forEach((feedback) => {
            descFeedbackDom.children.push({
                htmlDom: "li",
                className: "tools_ai1_results_list-item",
                innerHTML: feedback,
            });
        });

        return createDom(descFeedbackDom);
    }

    async function getSalaryResult() {
        const urlParams = new URLSearchParams(window.location.search);

        if (!urlParams.has("submission_id")) {
            window.location.href = window.location.origin;

            return;
        }

        const functionUrl = `https://dhmtktvfgukusnmmmwdf.supabase.co/functions/v1/salary-range-dev/${urlParams.get(
            "submission_id"
        )}`;

        const response = await fetch(functionUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const resultJson = await response.json();

        if (!response.ok) throw new Error(`Response from salary generator API had an error. Response: ${JSON.stringify(resultJson)}`);

        return resultJson["result"];
    }

    function getFormData() {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("data")) {
            const formDataStr = atob(urlParams.get("data"));

            return JSON.parse(formDataStr);
        }

        return null;
    }

    function displaySalaryResult() {
        getSalaryResult().then((salaryResult) => {
            const {
                salary_min,
                salary_median,
                salary_max,
                salary_adjustment,
                salary_feedback,
                description_feedback,
                salary_currency,
                role,
                job_description,
                location,
                industry,
            } = salaryResult;

            let adjusted_salary_min = salary_min ?? 0;
            let adjusted_salary_median = salary_median ?? 0;
            let adjusted_salary_max = salary_max ?? 0;

            if (
                salary_adjustment !== null &&
                salary_adjustment !== 0 &&
                typeof salary_adjustment !== "string" &&
                typeof salary_adjustment !== "undefined"
            ) {
                adjusted_salary_min =
                    adjusted_salary_min * parseFloat(salary_adjustment);
                adjusted_salary_median =
                    adjusted_salary_median * parseFloat(salary_adjustment);
                adjusted_salary_max =
                    adjusted_salary_max * parseFloat(salary_adjustment);
            }

            document.querySelector("[data-role-title]").innerHTML = role;
            document.querySelector("[data-location]").innerHTML = location;
            document.querySelector("[data-industry]").innerHTML = industry;

            document.querySelector("[data-salary-min]").innerHTML =
                formatCurrency(adjusted_salary_min, salary_currency);
            document.querySelector("[data-salary-median]").innerHTML =
                formatCurrency(adjusted_salary_median, salary_currency);
            document.querySelector("[data-salary-max]").innerHTML =
                formatCurrency(adjusted_salary_max, salary_currency);

            document.querySelector("[data-salary-feedback]").innerHTML = "";
            document
                .querySelector("[data-salary-feedback]")
                .append(getFeedbackUI(salary_feedback));

            document.querySelector("[data-description-feedback]").innerHTML =
                "";
            document
                .querySelector("[data-description-feedback]")
                .append(getFeedbackUI(description_feedback));

            hideSplashScreen();
            triggerJobDescriptionAnalysedEvent(salaryResult);
        }).catch(error => {
            console.error(error);
        });
    }

    function setPrivacyPolicyCheckbox() {
        const checkbox = document.querySelector(
            'input[data-name="Privacy Policy"]'
        );

        if (checkbox && checkbox.checked) {
            checkbox.previousSibling.classList.add("w--redirected-checked");
        }
    }

    function addLanguageResponseField() {
        const salaryForm = document.querySelector(
            'form[data-name="Salary Range Form"], [data-name="Salary Range Consultation Form"]'
        );

        let language = "de-DE";

        if (window.location.pathname.includes("en-salary-range-generator")) {
            language = "en-GB";
        }

        appendInputFieldToForm(salaryForm, "response_language", language);
    }

    function initConsultationRequestedEvent() {
        const salaryForm = document.querySelector(
            'form[data-name="Salary Range Form"], [data-name="Salary Range Consultation Form"]'
        );

        salaryForm &&
            salaryForm.addEventListener("submit", () => {
                let traits = {};

                salaryForm
                    .querySelectorAll("input[data-name]")
                    .forEach((input) => {
                        const fieldName = toSnakeCase(
                            input.dataset?.name?.toLowerCase()
                        );

                        if (fieldName) {
                            const value = input.value;

                            traits[fieldName] = value;
                        }
                    });

                SegmentEvents &&
                    SegmentEvents.trackEvent(
                        "Consultation Requested",
                        traits
                    );
            });
    }

    function triggerJobDescriptionAnalysedEvent(salaryResult) {
        const formData = getFormData();

        let traits = {};

        formData && Object.entries(formData).forEach(([key, value]) => {
            const fieldName = toSnakeCase(key);

            traits[fieldName] = value;
        });

        traits = { ...traits, ...salaryResult };

        SegmentEvents &&
            SegmentEvents.trackEvent("Job Description Analysed", traits);
    }

    return {
        displaySalaryResult,
        setPrivacyPolicyCheckbox,
        addLanguageResponseField,
        initConsultationRequestedEvent,
        triggerJobDescriptionAnalysedEvent,
        addTrackingFormHiddenFields,
    };
})();