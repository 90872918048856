document.addEventListener("DOMContentLoaded", () => {
    LanguageSwitcher.setLanguage();
});

const LanguageSwitcher = (function () {
    function switchLanguage(e) {
        const langClicked = e.target.id;
        if (typeof langClicked !== "undefined") {
            const newUrl = new URL(
                document.querySelector(
                    `link[rel="alternate"][hreflang="${langClicked}"]`
                ).href
            );
            window.location.replace(newUrl.pathname);
        }
    }

    function hideLanguageSwitcher(e) {
        const langSwitcherElem = document.querySelector(".language-selector");
        const hrefLangs = document.querySelectorAll(
            'link[rel="alternate"][hreflang]'
        );

        if (langSwitcherElem && hrefLangs && hrefLangs.length < 2) {
            langSwitcherElem.style.display = "none";
        }
    }

    function setLanguage() {
        const languages = document.querySelectorAll(
            ".language-selector-text:not(.divider)"
        );
        languages.forEach((lang) =>
            lang.addEventListener("click", switchLanguage)
        );

        hideLanguageSwitcher();
    }

    return {
        setLanguage,
    };
})();
